/* eslint-disable @typescript-eslint/no-empty-function */
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks'
import { useEffect, useMemo } from 'react'
import { usePendingOrders } from 'state/signatures/hooks'
import { usePendingTransactions, useTransactionCanceller } from 'state/transactions/hooks'

import { useLocalActivities } from './parseLocal'
import { Activity, ActivityMap } from './types'
//import useActivityQuery from './useActivityQuery.json'

/** Detects transactions from same account with the same nonce and different hash */
function findCancelTx(localActivity: Activity): string | undefined {
  // handles locally cached tx's that were stored before we started tracking nonces
  if (!localActivity.nonce || localActivity.status !== TransactionStatus.Pending) return undefined

  return undefined
}

/** Deduplicates local and remote activities */
function combineActivities(localMap: ActivityMap = {}): Array<Activity> {
  const txHashes = [...new Set([...Object.keys(localMap)])]

  return txHashes.reduce((acc: Array<Activity>, hash) => {
    const localActivity = (localMap?.[hash] ?? {}) as Activity

    if (localActivity.cancelled) {
      // Hides misleading activities caused by cross-chain nonce collisions previously being incorrectly labelled as cancelled txs in redux

      // Remote data only contains data of the cancel tx, rather than the original tx, so we prefer local data here
      acc.push(localActivity)
    } else {
      // Generally prefer remote values to local value because i.e. remote swap amounts are on-chain rather than client-estimated
      acc.push({ ...localActivity } as Activity)
    }

    return acc
  }, [])
}

export function useAllActivities(account: string) {
  const localMap = useLocalActivities(account)

  const updateCancelledTx = useTransactionCanceller()

  /* Updates locally stored pendings tx's when remote data contains a conflicting cancellation tx */
  useEffect(() => {
    Object.values(localMap).forEach((localActivity) => {
      if (!localActivity) return

      const cancelHash = findCancelTx(localActivity)

      if (cancelHash) updateCancelledTx(localActivity.hash, localActivity.chainId, cancelHash)
    })
  }, [account, localMap, updateCancelledTx])

  const combinedActivities = useMemo(() => combineActivities(localMap), [localMap])

  return { loading: false, activities: combinedActivities, refetch: () => {} }
}

export function usePendingActivity() {
  const pendingTransactions = usePendingTransactions()
  const pendingOrders = usePendingOrders()

  const hasPendingActivity = pendingTransactions.length > 0 || pendingOrders.length > 0
  const pendingActivityCount = pendingTransactions.length + pendingOrders.length

  return { hasPendingActivity, pendingActivityCount }
}
