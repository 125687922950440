import { Protocol } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { QuoteResult, QuoteState, URAQuoteType } from 'state/routing/types'
import { ClassicQuoteData, V3PoolInRoute } from 'state/routing/types'

import { routeAmountsToString, SwapRoute } from '../../smart-order-router'

// from routing-api (https://github.com/Uniswap/routing-api/blob/main/lib/handlers/quote/quote.ts#L243-L311)
export function transformSwapRouteToGetQuoteResult(
  tradeType: TradeType,
  amount: CurrencyAmount<Currency>,
  {
    quote,
    quoteGasAdjusted,
    route,
    estimatedGasUsed,
    estimatedGasUsedQuoteToken,
    estimatedGasUsedUSD,
    gasPriceWei,
    methodParameters,
    blockNumber,
  }: SwapRoute
): QuoteResult {
  const routeResponse: Array<V3PoolInRoute[]> = []

  for (const subRoute of route) {
    const { amount, quote, tokenPath } = subRoute

    const pools = subRoute.protocol === Protocol.V2 ? subRoute.route.pairs : subRoute.route.pools

    const curRoute: V3PoolInRoute[] = []
    for (let i = 0; i < pools.length; i++) {
      const nextPool = pools[i]
      const tokenIn = tokenPath[i]
      const tokenOut = tokenPath[i + 1]

      let edgeAmountIn = undefined
      if (i === 0) {
        edgeAmountIn = tradeType === TradeType.EXACT_INPUT ? amount.quotient.toString() : quote.quotient.toString()
      }

      let edgeAmountOut = undefined
      if (i === pools.length - 1) {
        edgeAmountOut = tradeType === TradeType.EXACT_INPUT ? quote.quotient.toString() : amount.quotient.toString()
      }
      const typed = nextPool as Pool

      curRoute.push({
        type: 'v3-pool',
        tokenIn: {
          chainId: tokenIn.chainId,
          decimals: tokenIn.decimals,
          address: tokenIn.address,
          symbol: tokenIn.symbol,
        },
        tokenOut: {
          chainId: tokenOut.chainId,
          decimals: tokenOut.decimals,
          address: tokenOut.address,
          symbol: tokenOut.symbol,
        },
        fee: typed.fee.toString(),
        liquidity: typed.liquidity.toString(),
        sqrtRatioX96: typed.sqrtRatioX96.toString(),
        tickCurrent: typed.tickCurrent.toString(),
        amountIn: edgeAmountIn,
        amountOut: edgeAmountOut,
      })

      // else {
      //   const reserve0 = nextPool.reserve0
      //   const reserve1 = nextPool.reserve1

      //   console.log({ reserve0, reserve1 })

      //   curRoute.push({
      //     type: 'v2-pool',
      //     tokenIn: {
      //       chainId: tokenIn.chainId,
      //       decimals: tokenIn.decimals,
      //       address: tokenIn.address,
      //       symbol: tokenIn.symbol,
      //     },
      //     tokenOut: {
      //       chainId: tokenOut.chainId,
      //       decimals: tokenOut.decimals,
      //       address: tokenOut.address,
      //       symbol: tokenOut.symbol,
      //     },
      //     reserve0: {
      //       token: {
      //         chainId: reserve0.currency.wrapped.chainId,
      //         decimals: reserve0.currency.wrapped.decimals,
      //         address: reserve0.currency.wrapped.address,
      //         symbol: reserve0.currency.wrapped.symbol,
      //       },
      //       quotient: reserve0.quotient.toString(),
      //     },
      //     reserve1: {
      //       token: {
      //         chainId: reserve1.currency.wrapped.chainId,
      //         decimals: reserve1.currency.wrapped.decimals,
      //         address: reserve1.currency.wrapped.address,
      //         symbol: reserve1.currency.wrapped.symbol,
      //       },
      //       quotient: reserve1.quotient.toString(),
      //     },
      //     amountIn: edgeAmountIn,
      //     amountOut: edgeAmountOut,
      //   })
      // }
    }

    routeResponse.push(curRoute)
  }

  const result: ClassicQuoteData = {
    methodParameters,
    blockNumber: blockNumber.toString(),
    amount: amount.quotient.toString(),
    amountDecimals: amount.toExact(),
    quote: quote.quotient.toString(),
    quoteDecimals: quote.toExact(),
    quoteGasAdjusted: quoteGasAdjusted.quotient.toString(),
    quoteGasAdjustedDecimals: quoteGasAdjusted.toExact(),
    gasUseEstimateQuote: estimatedGasUsedQuoteToken.quotient.toString(),
    gasUseEstimateQuoteDecimals: estimatedGasUsedQuoteToken.toExact(),
    gasUseEstimate: estimatedGasUsed.toString(),
    gasUseEstimateUSD: estimatedGasUsedUSD.toExact(),
    gasPriceWei: gasPriceWei.toString(),
    route: routeResponse,
    routeString: routeAmountsToString(route),
  }

  return { state: QuoteState.SUCCESS, data: { routing: URAQuoteType.CLASSIC, quote: result, allQuotes: [] } }
}
